<template>
  <v-container fill-height>
    <v-row align="center" justify="center" class="text-center">
      <v-col>
        <h1>403</h1>
        <h1><strong>
            You are not authorized to access this page
        </strong></h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Page403",
};
</script>
